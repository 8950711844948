<!-- Base Grid -->

<template>
 <!-- @selected="(e)=>selectedItems=e" -->
 <!-- @input="$emit('selected',selected)" -->
  <!-- @input="$emit('input', $event.target.value)" -->
  <v-data-table
    v-bind="$attrs"        
    class="base_Fgrid"       
    @item-expanded.native.stop="check_editable2"
    @item-expanded ="expansion"
    @input="$emit('input',$event)"
    :headers="Entorno.grid.headers"
    :items="records"
    :value="value"   
    single-expand
    :expanded.sync="$store.state[padre].rowSelected">    
  
      <!-- Botones top -->      
      <template v-slot:top="">
        <slot name="top_btns" :Sitem="row_selected"></slot>
      </template>

       <!-- Slot items -->
      <!-- <template v-for="header in hslot" v-slot:[`item.${header.value}`]="{ item }">
        <div :key="header.value">
          <slot :name="[`${header.value}`]" :Sitem="item">
            {{item[header.value]}} 
          </slot> 
        </div>
      </template> -->

      <!-- HE MODIFICADO LA FUNCION HSLOT -->
      <template v-for="header in Entorno.grid.headers" v-slot:[`item.${header.value}`]="{ item }">              
        <div v-if="header.slot" :key="header.value">
          <slot :name="[`${header.value}`]" :Sitem="item">
              {{item[header.value]}}
          </slot>
        </div>

        <div v-else :key="header.value">
          <div :style="header.style? header.style : ''">
            {{ header.filtro ? $options.filters[header.filtro](item[header.value],header.arg) : item[header.value] }} 
          </div>
        </div>        
      </template>


      <!-- Botones expansible -->
      <template v-slot:item.data-table-expand="{isExpanded, expand }">
        <v-btn 
          icon 
          @click="!isExpanded? (is_hijoEdit()? error_expand() : expand(true)) : (is_hijoEdit()? error_expand() : expand(false))">
            <v-icon>{{ isExpanded? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>     
      </template>

      <!-- Expansible -->
      <template v-slot:expanded-item="{ headers, item }">       
          <td :colspan="headers.length" class="pt-2 pb-1">
            <slot
              name="expansible_ctrls"
              :Sitem="item"
              :Sheaders="headers">                     
            </slot>
          </td>
      </template> 
  </v-data-table>
  
</template>


<script>

    export default {
      props: {
        padre: { type: String, default: '' },        
        Entorno: { type: Object, default: () => undefined },
        headers:Array,
        value: { type: Array, default: () => []}

      },

      data() {
        return {
          row_selected: null, 
          selected:[]     
        };
      },
  
    computed:{
      records(){
        return this.$store.state[this.padre].records;
      },
        
      hslot() {
        
         return this.Entorno.grid.headers.filter(item=>item.value)
       }
    },

    methods: {
         selecteds(e){
           console.log("selected:",e)
         },
        //
        expansion(evt) {          
          if (evt.value) this.$emit('onEvent', { accion:0 });
          this.$emit('onEventExpansion',evt);
        },


        // devuelvo si el hijo está en edición
        is_hijoEdit() {                
          var hijo= this.$store.state[this.padre].hijo;          
          if (!hijo) return false;          
          return this.$store.state[hijo].estado === "editar" || this.$store.state[hijo].estado=== "nuevo"? true : false;
        },


        //
        error_expand() {
          this.$root.$alert.open('Está editanto una fila', 'error');          
        },
       seleccion(evt){
         console.log("seleccion",evt)
       }
      },

    };
</script>
